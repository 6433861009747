<template>
    <div class="bg-[#F5F6F9] mt-[30px]">
        <div class="wps py-[30px]">
            <div class="flex">
                <div class="flex-1 text-[28px] font-bold">解决方案</div>
                <div class="ml-auto flex" @click="navigateTo('/solution')">
                    <div class="bg-[#00197A] py-[11px] px-[38px] text-[13px] text-[#fff]">查看更多</div>
                    <div class="bg-[#00197A] borleft py-[11px] px-[13px] flex justify-center items-center"><el-icon color="#fff"><CaretRight /></el-icon></div>
                </div>
            </div>
            <!-- s -->
            <div class="mt-[60px] bordertigj py-[30px]">
                <el-row :gutter="24" class=" overflow-hidden">
                    <el-col class="mb-[18px]"  v-for="(item,index) in list" :key="index" @mouseover="changeColor(index)" @click="navigateTo('/solutionDetails',{search:item.search})"  @mouseleave="changeColormouseleave"  :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                        <div class="cursor-pointer animate__animated" :class="{'active  animate__pulse':cureindex === index}" >
                            <img class="w-full m-h-[280px]" :src="item.url" alt="" >
                            <div class="bg-[#fff] py-[16px] bges">
                                <div class="px-[20px] text-[15px] ">
                                    <div>
                                        <img class="w-[20px] h-[20px]" v-if="cureindex === index" src="../assets/logo/cut_white@2x.png" alt="">
                                        <img class="w-[20px] h-[20px]" v-else src="../assets/logo/cut_right@2x.png" alt="">
                                    </div>
                                    <div class="w-[25px] h-[2px]  bg-[#c8c8c9] mt-[20px]"></div>
                                    <div class="flex mt-[14px] bges1">
                                        <div class="flex-1 text-[15px] font-bold flex bges items-center">{{item.title}}</div>
                                        <div class="borders w-[28px] h-[28px] flex justify-center items-center"><el-icon><CaretRight /></el-icon></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {ref} from 'vue'
    import 'animate.css';
import { navigateTo, } from '../utils/jump';
    const cureindex = ref('')

    const changeColor = (index)=>{
        cureindex.value = index
    }
    const changeColormouseleave = ()=>{
        cureindex.value = ''
    }
    const list = ref([
        {title:'智慧水泵解决方案',search:'水泵',url:'https://iita-factory.oss-cn-shanghai.aliyuncs.com/web-iita/iconisx.jpg'},
        {title:'智能充电桩解决方案',search:'充电桩',url:'https://iita-factory.oss-cn-shanghai.aliyuncs.com/web-iita/sw1.jpg'},
        {title:'智慧水务解决方案',search:'水务',url:'https://iita-factory.oss-cn-shanghai.aliyuncs.com/web-iita/sw2.jpg'},
        {title:'OPENIITA开放平台',search:'OPENIITA开放平台',url:'https://iita-factory.oss-cn-shanghai.aliyuncs.com/web-iita/sw3.png'},
    ])
</script>
<style scoped>
    .bordertigj{
        border-top: 1px solid #e3e3e5;
    }
    .borleft{
        border-left: 1px solid #505f9c;
    }
    .borders{
        border: 1px solid #d4d4d4;
    }
    .active{

    }
   .active .bges{
    background: #1936AD;
    color: #fff;
    box-shadow: 0 20px 30px 0 #00197a33;
   }
   .active .bges1{
    background: #5669ba;
   }
</style>