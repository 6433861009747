import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/productCenter',
    name: 'productCenter',
    component: () => import( '../views/productCenter.vue')
  },
  {
    path: '/productdetails',
    name: 'productdetails',
    component: () => import( '../views/productdetails.vue')
  },
  {
    path: '/solution',
    name: 'solution',
    component: () => import( '../views/solution.vue')
  },
  {
    path: '/solutionDetails',
    name: 'solutionDetails',
    component: () => import( '../views/solutionDetails/solutionDetails.vue')
  },
  {
    path: '/aboutTower',
    name: 'aboutTower',
    component: () => import( '../views/aboutUs/aboutTower.vue')
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import( '../views/aboutUs/partners.vue')
  },
  {
    path: '/purchasingchannels',
    name: 'purchasingchannels',
    component: () => import( '../views/aboutUs/purchasingchannels.vue')
  },
  {
    path: '/technicalSupport',
    name: 'technicalSupport',
    component: () => import( '../views/technicalSupport/technicalSupport.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
