<template>
    <div class="wps m-auto  relative topborder bg-[#F7F8FB] py-10px" :style="{marginTop:martop}">
        <el-row :gutter="10">
            <el-col v-for="(item,index) in list" :key="index" :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                <div class="flex-1 text-center py-[30px] rightborder" @mouseover="changeColor(index)">
                    <div class="text-center">
                        <img class="w-[50px] h-[50px] inline-block mb-[10px] animate__animated animate__swing" :class="{'animate__tada': cureindex === index}" :src="item.url" alt="">
                    </div>
                    <div class="text-[#000] text-[20px] font-bold mb-[12px]">{{item.title}}</div>
                    <div class="text-[#777] text-[14px]">{{item.describe}}</div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script setup>
import { defineProps,ref } from 'vue';
const props = defineProps({
    martop:{
        type:String,
        default:'-8px'
    }

})
const list = ref([
        {
            title: '开源',
            describe: '机电类产品与工业设计完美融合',
            url:'https://iita.oss-cn-shenzhen.aliyuncs.com/officialwebsite/ic_expand%402x.png'
        },
        {
            title: '开放',
            describe: '数十项专利技术，打破传统形态',
            url:'https://iita.oss-cn-shenzhen.aliyuncs.com/officialwebsite/ic_opensource%402x.png'
        },
        {
            title: '合作共赢',
            describe: '20余年行业经验，提供定制化解决方案',
            url:'https://iita.oss-cn-shenzhen.aliyuncs.com/officialwebsite/ic_cooperation%402x.png'
        },
        {
            title: '共同发展',
            describe: '拥有专业的售后人员，完善的售后体系',
            url:'https://iita.oss-cn-shenzhen.aliyuncs.com/officialwebsite/ic_development%402x.png'
        },
    ])
    const cureindex = ref('')
    const changeColor = (index) => {
        cureindex.value = index
    }
</script>
<style scoped>
.topborder {
    border-top: 8px solid #3F7EEF;
}

.rightborder {
    border-right: 1.5px solid #f6f6f6;
}
</style>