<template>
    <div class="bg-[#101010] ">
        <div class="autowidth wps flex pt-[80px] min-991axi border-bottom" :class="{'mt-[60px]':props.mt}">
            <el-row :gutter="10" class="w-full">
                <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
                    <div class="flex-1">
                        <div class="text-[14px] text-[#cecece]">
                            <img class="w-[94px] h-[35px]" src="../assets/baang/loginbottom.jpg" alt="">
                        </div>
                        <div class="mt-[36px] text-[#ABABAB]">服务热线</div>
                        <div class="text-[#fff] text-[24px] pt-[10px] pb-[24px]"><a class="text-[#fff] no-underline" href="tel:400-99-55270">400-99-55270</a></div>

                        <div class="text-[#ABABAB]">业务电话</div>
                        <div class="text-[#fff] text-[24px] pt-[10px] pb-[24px]"><a class="text-[#fff] no-underline" href="tel:18022906280">18022906280</a></div>
                        
                        <div class="text-14px text-[#cecece]">公司地址</div>
                        <div class="text-[#fff] text-[18px] pt-[16px] pb-[24px] ">深圳市宝安区共和工业路45号华丰商务中心A座308</div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="17" :lg="17" :xl="17">
                    <div class="flex-1 ">
                        <el-row :gutter="10" class="flex w-full ">
                            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                                <div class="flex-1 text-[#ABABAB] text-[14px]">
                                    <div class="text-[16px] text-[#fff] pb-[24px]">关于</div>
                                    <div class="py-[8px] cursor-pointer" @click="navigateToref('/aboutTower')">关于铱塔</div>
                                    <div class="py-[8px]" @click="navigateToref('/partners')">合作伙伴</div>
                                </div>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
                                <div class="flex-1 text-[#ABABAB] text-[14px]">
                                    <div class="text-[16px] text-[#fff] pb-[24px]">解决方案</div>
                                    <div class="py-[8px] cursor-pointer" @click="navigateToref('/solutionDetails', { search: '水泵' })">智能水泵方案</div>
                                    <div class="py-[8px] cursor-pointer" @click="navigateToref('/solutionDetails', { search: '充电桩' })">智能充电桩方案</div>
                                    <div class="py-[8px] cursor-pointer" @click="navigateToref('/solutionDetails', { search: '水务' })">智慧水务方案</div>
                                    <div class="py-[8px] cursor-pointer" @click="navigateToref('/solutionDetails', { search: '大鹏' })">智慧农业方案</div>
                                    <div class="py-[8px] cursor-pointer" @click="navigateToref('/solutionDetails', { search: '建筑' })">智慧建筑方案</div>
                                    <div class="py-[8px] cursor-pointer" @click="navigateToref('/solutionDetails', { search: 'OPENIITA开放平台' })">OPENIITA开放平台方案</div>
                                </div>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                                <div class="flex-1 text-[#ABABAB] text-[14px]">
                                    <div class="text-16px text-[#fff] pb-24px">产品中心</div>
                                    <div class="py-[8px] cursor-pointer" @click="navigateToref('/productCenter', { search: '传感器' })">压力传感器</div>
                                    <div class="py-[8px] cursor-pointer" @click="navigateToref('/productCenter', { search: '传感器' })">超声波传感器</div>
                                    <div class="py-[8px] cursor-pointer" @click="navigateToref('/productCenter', { search: '永磁电机' })">通用水润滑电机</div>
                                    <div class="py-[8px] cursor-pointer" @click="navigateToref('/productCenter', { search: '永磁电机' })">通用轴承电机</div>
                                    <div class="py-[8px] cursor-pointer" @click="navigateToref('/productCenter', { search: '电控' })">电控</div>
                                    <div class="py-[8px] cursor-pointer" @click="navigateToref('/productCenter', { search: '流道设计' })">流道设计</div>
                                </div>
                            </el-col>
                            <el-col  :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
                                <div class="flex">
                                    <div class="ml-auto">
                                        <div class="pb-[24px] text-[#fff]">关注我们</div>
                                        <div class="flex text-[#fff]">
                                            <el-row :gutter="10">
                                                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                                    <div class="flex-1 mr-[40px]">
                                                        <div>
                                                            <img class="w-[120px] h-[120px]" src="../assets//baang/QRCode@2x.png" alt="">
                                                        </div>
                                                        <p class="text-center mt-[10px]">公众号</p>
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </div>
                                </div>
                           
                            </el-col>
                        </el-row>

                    </div>
                    <!-- <div class=" flex text-right">
                        <div class="ml-auto">
                            <div class="pb-[24px] text-[#fff]">关注我们</div>
                            <div class="flex text-[#fff]">
                                <el-row :gutter="10">
                                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                        <div class="flex-1 mr-[40px]">
                                            <div>
                                                <img class="w-[120px] h-[120px]" src="../assets//baang/QRCode@2x.png" alt="">
                                            </div>
                                            <p class="text-center mt-[10px]">公众号</p>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div> -->
                </el-col>
                <!-- <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    
                </el-col> -->
            </el-row>


        </div>
        <div class="text-center text-[#c6c6c6] py-[20px] text-[15px]">
            备案号： <a  class="text-[#c6c6c6] text-[15px] decoration-none" href="https://beian.miit.gov.cn" target="_blank">粤ICP备2023036352号-2</a> | 版权所有铱塔（深圳）智联有限公司
        </div>

    </div>
</template>
<script setup>
import{defineProps} from 'vue'
    import { navigateToref } from '../utils/jump';
 const props = defineProps({
    mt:{
        type:Boolean,
        default:true
    }
 })
</script>

<style scoped>
@media (max-width: 991px) {
    .min-991axi{
        padding-left: 10px;
    }
}
.border-bottom{
    border-bottom: 1px solid #323232;
    padding-bottom: 50px;
}
</style>