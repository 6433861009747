<template>
    <div class="mt-[-78px] text-center">
          <el-carousel :interval="5000" class="relative" indicator-position="none"  :autoplay="false" arrow="always" :height="isMobile ? '30vh':'70vh'" @change="changeclick"  >
          <el-carousel-item v-for="(item,index) in list" :key="index">
          <el-image class="max-w-[2000px]" :src="item.img" fit="" />
          </el-carousel-item>
          <div class="mt-[-25px] absolute bottom-[2%] z-[10] left-0 flex w-full clsabs">
            <div class="flex-1 relative"><div class="actices"></div></div>
            <div class="flex-1 flex">
                <div class="flex-1 relative " v-for="(item,index) in list" :key="index">
                    <div class="w-[30px] h-[30px]  relative inline-block rounded-full" :class="{'acsiox' : indexcure === index}">
                     <span class="filletclass  absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"></span>
                    </div>
                </div>
            </div>
            <div class="flex-[3] relative"><div class="actices"></div></div>
            <div class="flex-1 text-[#fff] text-[13px] flex justify-center items-center">SCROLLDOWN</div>
            <div class="flex-[3] relative"><div class="actices"></div></div>
            <div class="flex-1 text-left relative">
                <span class="pl-[30px] apbpadd text-[#fff] text-[15px] absolute left-[0px] top-1/2 transform  -translate-y-1/2">{{indexcure + 1}} / {{ list.length }}</span>
            </div> 
            <div class="flex-1 relative"><div class="actices"></div></div>
          </div>
        </el-carousel>

      </div>
</template>
<script setup>
import { ref,onMounted } from 'vue'

const list  = ref([
    {img:'https://iita-factory.oss-cn-shanghai.aliyuncs.com/web-iita/homepage_banner_01%402x.png'},
    {img:'https://iita-factory.oss-cn-shanghai.aliyuncs.com/web-iita/homepage_banner_01%402x.png'},
    {img:'https://iita-factory.oss-cn-shanghai.aliyuncs.com/web-iita/homepage_banner_01%402x.png'},
    {img:'https://iita-factory.oss-cn-shanghai.aliyuncs.com/web-iita/homepage_banner_01%402x.png'},
])

const isMobile = ref(false)
onMounted(() => {
  // 检测移动设备
  const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  isMobile.value = isMobileDevice
})

const indexcure = ref(0)
const changeclick = (e)=>{
  indexcure.value = e
}
const prevclick = ()=>{
  if(indexcure.value > 0){
    indexcure.value = indexcure.value - 1
  }
}

</script>
<style scoped>
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
::v-deep .el-carousel__indicators--horizontal{
    left: 86%;
    z-index: 11;
    bottom: 20px;
}
.actices{
    width: 100%;
    height: 1px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
::v-deep .el-carousel__arrow--left{
    left: 86%;
    top: 95%;
    z-index: 11;
}
::v-deep .el-carousel__arrow--right{
    left: 88%;
    top: 95%;
    z-index: 11;
}
.filletclass{
    width: 3px;
    height: 3px;
    border-radius: 50%;
    display: inline-block;
    background: #fff;
}
.acsiox{
    border: 1px solid #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
}
@media (max-width: 1580px) {
    .clsabs{
        bottom: 5% !important;
    }
}
@media (max-width: 1200px) {
    .clsabs{
        bottom: 34% !important;
    }
    ::v-deep .el-carousel__arrow--left{
    left: 86%;
    top: 63%;
    z-index: 11;
    }
    ::v-deep .el-carousel__arrow--right{
        left: 88%;
        top: 63%;
        z-index: 11;
    }
    .apbpadd{
        padding-left: 10px;
    }
}
@media (max-width: 1490px) {
    .clsabs{
        bottom: 14% !important;
    }
    ::v-deep .el-carousel__arrow--left{
    left: 86%;
    top: 83%;
    z-index: 11;
    }
    ::v-deep .el-carousel__arrow--right{
        left: 88%;
        top: 83%;
        z-index: 11;
    }
    .apbpadd{
        padding-left: 10px;
    }
}
@media (max-width: 1375px) {
    .clsabs{
        bottom: 24% !important;
    }
    ::v-deep .el-carousel__arrow--left{
    left: 86%;
    top: 73%;
    z-index: 11;
    }
    ::v-deep .el-carousel__arrow--right{
        left: 88%;
        top: 73%;
        z-index: 11;
    }
    .apbpadd{
        padding-left: 10px;
    }
}
@media (max-width: 1200px) {
    .clsabs{
        bottom: 34% !important;
    }
    ::v-deep .el-carousel__arrow--left{
    left: 86%;
    top: 63%;
    z-index: 11;
    }
    ::v-deep .el-carousel__arrow--right{
        left: 88%;
        top: 63%;
        z-index: 11;
    }
    .apbpadd{
        padding-left: 10px;
    }
}
@media (max-width: 1045px) {
    .clsabs{
        bottom: 40% !important;
    }
    ::v-deep .el-carousel__arrow--left{
    left: 86%;
    top: 57.5%;
    z-index: 11;
    }
    ::v-deep .el-carousel__arrow--right{
        left: 88%;
        top: 57.5%;
        z-index: 11;
    }
    .apbpadd{
        padding-left: 10px;
    }
}
@media (max-width: 990px) {
    .clsabs{
        display: none;
    }
    .el-image{
    height: 100%;
    }
    ::v-deep .el-carousel__arrow--left{
        display: none;
    }
    ::v-deep .el-carousel__arrow--right{
        display: none;
    }
}

</style>