<template>
    <div class="wps py-[30px]">
        <div class="flex">
                <div class="flex-1 text-[28px] font-bold">行业动态</div>
                <div class="ml-auto flex">
                    <div class="bg-[#00197A] py-[11px] px-[38px] text-[13px] text-[#fff]">查看更多</div>
                    <div class="bg-[#00197A] borleft py-[11px] px-[13px] flex justify-center items-center"><el-icon color="#fff"><CaretRight /></el-icon></div>
                </div>
        </div>
        <div class="mt-[60px] bordertigj py-[30px] ">
            <el-row :gutter="24" class=" overflow-hidden">
                <el-col class="mb-[18px] " :xs="24" :sm="12" :md="16" :lg="16" :xl="16">
                    <div class="flex_jub h-full">
                    <div class="text-[15px] text-[#777]">{{ datadel.time }}</div>
                    <div class="text-[25px] text-[#111]">{{ datadel.title }}</div>
                    <div class="text-[15px] text-[#666]">{{ datadel.describe }}</div>
                    <div class=" flex cursor-pointer">
                        <div class="borders borders_right py-[11px] px-[38px] text-[13px] text-[#1936AD]">查看详情</div>
                        <div class="borders borders_left  py-[11px] px-[13px] flex justify-center  items-center"><el-icon color="#1936AD"><CaretRight /></el-icon></div>
                    </div>
                    </div>
                </el-col>
                <el-col class="mb-[18px]" :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                    <img class="m-w-[500px] h-[270px]" src="../assets/baang/wps1.jpg" alt="">
                </el-col>
            </el-row>
        </div>
        <!-- s list -->
        <div class="mt-[50px]">
            <div class="grid grid-cols-2 newboxbottom ">
                <div class="flex newboxbtop py-[24px] oudte animate__animated newrights cursor-pointer" v-for="(item,index) in listData" :key="index" @mouseover="changeColor(index,item)" :class="{'activ animate__flipInX': indexcure === index}">
                    <div class="text-[16px] text-[#111] pl-[24px]  activetext">{{ item.title }}</div>
                    <div class="ml-auto text-[14px] text-[#777]  activetext newrights">{{ item.time }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
 import {ref} from 'vue'
 import 'animate.css';
 const listData = ref([
    {title:"无人机在新能源领域中的应用",time:"2023/12/21",describe:"国务院提请审议国务院机构改革方案，组建国家数据局，负责协调推进数据基础制度建设，统筹数据资源整合共享和开发利用，统筹推进数字中国、数字经济、数字社会规划和建设等"},
    {title:"中共中央、国务院印发《数字中国建设整体布局规划》",time:"2023/12/21",describe:""},
    {title:"2023年数字政府建设开新局 多地加紧制定“路线图”抢抓机遇",time:"2023/12/21",describe:""},
    {title:"组建国家数据局，智慧交通再迎利好",time:"2023/12/21",describe:""},
 ])

 const indexcure = ref(0)
 const datadel = ref()
 datadel.value = listData.value[0]
 const changeColor = (index,item)=>{
    indexcure.value = index
    datadel.value = item
 }

</script>
<style scope>
  .borleft{
        border-left: 1px solid #505f9c;
    }
    .bordertigj{
        border-top: 1px solid #e3e3e5;
    }
    .flex_jub{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .borders{
        border: 1px solid #1936AD;
    }
    .borders_right{
        border-rigth:none;
    }
    .borders_left{
        border-left:none;
    }
    .newboxbtop{
        border-top: 1px solid #e7e7e7;
    }
    .newboxbottom{
        border-bottom: 1px solid #e7e7e7;
    }
    .activ{
        background: #1936AD;
    }
    .activ .activetext{
        color: #fff;
    }
    .oudte:nth-child(odd) {
        padding-right:24px ;
    }
    .newrights{
        position: relative;
    }
    .newrights:nth-child(odd)::after {
        content: '';
        width: 1px;
        height: 30px;
        display: inline-block;
        background:#e4e4e4 ;
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translate(0,-50%);
    }
</style>