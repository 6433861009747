import router from "../router/index";
/**
 * Navigates to the specified URL using the router.
 * @param {string} url - The URL to navigate to.
 * @param {object} params - The parameters to pass to the URL.
 */
export const navigateTo = (url, data,win) => {
  console.log(data)
  router.push({ path: url, query: data });
    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
  };

  export const navigateToref = (url, data) => {
    console.log(data)
      router.push({ path: url, query: data });
      setTimeout(() => {
        window.location.reload();
      }, 100);
    };

    export const navigateToWin = (url) => {
      console.log('1')
        window.open(url, '_blank');
    };