<template>
    <div class="wps">
        <el-row :gutter="20" class=" overflow-hidden">
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" >
                    <div class="boxse" @click="navigateTo('/solutionDetails',{search:'水泵'})">
                        <img class="w-full" src="../assets/baang/pic_01@2x.png" alt="">
                        <div class="absolute  bottom-[20px] left-[36px] text-[#fff] ">
                            <div class="text-[24px] mb-[14px] ">软件定义泵<img class="w-[30px] h-[30px] inline-block" style="vertical-align: sub;" src="../assets//logo/ic_arrow_down@2x.png" alt=""></div>
                            <template class="textShow">
                                <div class="flex w-full animate__bounceIn">
                                    <div class="text-[16px] ">统一设备接口、参数、通信架构，实现软件定义泵 </div>
                                    <div class=" text-right mr-[36px] ml-auto">
                                        <img class="w-[90px] h-[60px]" src="../assets/logo/iconss.png" alt="">
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16" >
                    <div class="boxse" @click="navigateTo('/solutionDetails',{search:'水泵'})">
                        <img class="w-full" src="../assets/baang/pic_02@2x.png" alt="">
                        <div class="absolute w-[90%] bottom-[20px] left-[36px] text-[#fff] ">
                            <div class="text-[24px] mb-[14px] ">场景OTA升级<img class="w-[30px] h-[30px] inline-block" style="vertical-align: sub;" src="../assets//logo/ic_arrow_down@2x.png" alt=""></div>
                            <template class="textShow">
                                <div class="flex w-full animate__bounceIn">
                                    <div class="text-[16px] ">统一设备接口、参数、通信架构，实现软件定义泵</div>
                                    <div class=" text-right mr-[36px] ml-auto">
                                        <img class="w-[90px] h-[60px]" src="../assets/logo/iconss.png" alt="">
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </el-col>
            </el-row>
        <!-- 3 -->
        <el-row :gutter="20" class=" overflow-hidden">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <div class="boxse" @click="navigateTo('/solutionDetails',{search:'水泵'})">
                    <img class="w-full" src="../assets/baang/pic_03@2x.png" alt="">
                    <div class="absolute w-[90%] bottom-[20px] left-[36px] text-[#fff] ">
                            <div class="text-[24px] mb-[14px] ">零配件智能化、标准化<img class="w-[30px] h-[30px] inline-block" style="vertical-align: sub;" src="../assets//logo/ic_arrow_down@2x.png" alt=""></div>
                            <template class="textShow animate__bounceIn">
                                <div class="flex w-full ">
                                    <div class="text-[16px] ">统一设备接口、参数、通信架构，实现软件定义泵</div>
                                    <div class=" text-right mr-[36px] ml-auto">
                                        <img class="w-[90px] h-[60px]" src="../assets/logo/iconss.png" alt="">
                                    </div>
                                </div>
                            </template>
                        </div>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                <div class="boxse">
                    <img class="w-full" src="../assets/baang/pic_04@2x.png" alt="">
                    <div class="absolute w-[90%] bottom-[20px] left-[36px] text-[#fff] ">
                            <div class="text-[24px] mb-[14px] ">无限探索 开放创新<img class="w-[30px] h-[30px] inline-block" style="vertical-align: sub;" src="../assets//logo/ic_arrow_down@2x.png" alt=""></div>
                            <template class="textShow animate__bounceIn">
                                <div class="flex w-full ">
                                    <div class="text-[16px] ">统一设备接口、参数、通信架构，实现软件定义泵</div>
                                    <div class=" text-right mr-[36px] ml-auto">
                                        <img class="w-[90px] h-[60px]" src="../assets/logo/iconss.png" alt="">
                                    </div>
                                </div>
                            </template>
                        </div>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <div class="boxse" @click="navigateTo('/solutionDetails',{search:'水泵'})">
                    <img class="w-full" src="../assets/baang/pic_05@2x.png" alt="">
                    <div class="absolute w-[90%] bottom-[20px] left-[36px] text-[#fff] ">
                            <div class="text-[24px] mb-[14px] ">智能预测 防患未然<img class="w-[30px] h-[30px] inline-block" style="vertical-align: sub;" src="../assets//logo/ic_arrow_down@2x.png" alt=""></div>
                            <template class="textShow animate__bounceIn">
                                <div class="flex w-full ">
                                    <div class="text-[16px] ">统一设备接口、参数、通信架构，实现软件定义泵</div>
                                    <div class=" text-right mr-[36px] ml-auto">
                                        <img class="w-[90px] h-[60px]" src="../assets/logo/iconss.png" alt="">
                                    </div>
                                </div>
                            </template>
                        </div>
                </div>
            </el-col>
        </el-row>
        <!-- 2 -->
        <el-row :gutter="20" class=" overflow-hidden">
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
                    <div class="boxse" @click="navigateTo('/solutionDetails',{search:'OPENIITA开放平台'})">
                    <img class="w-full" src="../assets/baang/pic_06@2x.png" alt="">
                    <div class="absolute w-[90%] bottom-[20px] left-[36px] text-[#fff] ">
                            <div class="text-[24px] mb-[14px] ">构建“云-管-边-端”统一物联架构体系<img class="w-[30px] h-[30px] inline-block" style="vertical-align: sub;" src="../assets//logo/ic_arrow_down@2x.png" alt=""></div>
                            <template class="textShow animate__bounceIn">
                                <div class="flex w-full ">
                                    <div class="text-[16px] ">统一设备接口、参数、通信架构，实现软件定义泵</div>
                                    <div class=" text-right mr-[36px] ml-auto">
                                        <img class="w-[90px] h-[60px]" src="../assets/logo/iconss.png" alt="">
                                    </div>
                                </div>
                            </template>
                        </div>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <div class="boxse" @click="navigateTo('/technicalSupport')">
                    <img class="w-full" src="../assets/baang/pic_07@2x.png" alt="">
                    <div class="absolute w-[90%] bottom-[20px] left-[36px] text-[#fff] ">
                            <div class="text-[24px] mb-[14px] ">开源 开放 合作共赢<img class="w-[30px] h-[30px] inline-block" style="vertical-align: sub;" src="../assets//logo/ic_arrow_down@2x.png" alt=""></div>
                            <template class="textShow animate__bounceIn">
                                <div class="flex w-full ">
                                    <div class="text-[16px] ">统一设备接口、参数、通信架构，实现软件定义泵</div>
                                    <div class=" text-right mr-[36px] ml-auto">
                                        <img class="w-[90px] h-[60px]" src="../assets/logo/iconss.png" alt="">
                                    </div>
                                </div>
                            </template>
                        </div>
                </div>
            </el-col>
        </el-row>
       
    </div>
</template>
<script setup>
    import {ref} from 'vue'
    import 'animate.css';
    import { navigateTo } from '../utils/jump';
    const list1 =  ref([
        {img:'',title:'软件定义泵',tipe:'统一设备接口、参数、通信架构，实现软件定义泵',post:'left',show:false},
        {img:'',title:'场景OTA升级',tipe:'',post:'right',show:false}
    ])
    const list2 =  ref([
        {img:'',title:'零配件智能化、标准化',tipe:''},
        {img:'',title:'无限探索 开放创新',tipe:''},
        {img:'',title:'智能预测 防患未然',tipe:''}
    ])
    const list3 =  ref([
        {img:'',title:'构建“云-管-边-端”统一物联架构体系',tipe:''},
        {img:'',title:'开源 开放 合作共赢',tipe:''}
    ])

    const changeColor = ()=>{
        console.log('1')
    }

</script>
<style scoped>
.boxse{
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
    
}
.boxse .disbolxc{
    display: block;
}
.boxse {
  position: relative;
  overflow: hidden;
}

.textShow {
  visibility: hidden;
  /* opacity: 0; */
  display: none;
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth transition for opacity and visibility */
}

.el-col:hover .textShow {
  visibility: visible;
  display: block;
}
</style>