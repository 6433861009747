<template>
  <router-view/>
</template>
<style>
  .wps{
    width: 1280px;
    margin: auto;
  }
  @media (max-width: 991px) {
    .wps{
    width: 100%;
    margin: auto;
    padding: 0 20px;
  }
}
</style>


