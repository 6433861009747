<template>
  <div class="relative w-full  h-[78px]  animate__animated  z-200" :class="{'activesce animate__fadeIn ': indexcure == 1 || indexcure == 2 || indexcure == 3 || indexcure == 4}">
    <div class="w-full  bor_btom flex absolute  left-1/2 top-0 transform -translate-x-1/2  z-20  ">
      <div class="wps py-[22px] flex">
     <img class="w-[94px] h-[35px] cursor-pointer" @click="navigateTo('/')" src="../assets/logo/IITALOGO_2@2x.png" alt="">
     <div class="ml-auto flex text-right text-[17px] font-semibold justify-center items-center cursor-pointer relative min-991max">
        <div  v-for="(item,index) in datares" :key="index" :class="{ 'actice': item.index === acticechun }" class="flex-1  flex w-[100px] animate__animated animate__bounceIn" >
          <div class="ml-auto relative hover:text-[#1936AD] text-[#fff] textfff" @mouseover="changeColor(index)" @click="navigateTo(item.path,'','win')">{{item.title}}
            <div class=" absolute w-full h-3px  bottom-[-28px]" :class="{ 'botderbottom': item.index === acticechun }"></div>
          </div>
        </div>
         <!-- 产品中心 -->
         <div v-if="indexcure === 1" @mouseleave="changeColormouseleave" class="animate__animated animate__fadeIn absolute z-[1] top-[57px] w-[650px] right-[-90px] bg-[#fff] botderbottom1 flex text-left ">
              <div class="flex-1 px-[25px] botderright my-[30px] text-center" v-for="(ite,indexs) in menu2" :key="indexs">
                <div class="mb-[16px]"><img class="w-[15px] h-[18px] inline-block mr-[5px]" src="../assets/logo/icon_1.jpg" alt="">{{ite.title}}</div>
                <div @click="navigateToref('/productCenter',{search:ite.title})" class="font-[400] text-[15px] mb-[10px] text-[#777] hover:text-[#1936AD]" v-for="(ites,indexa) in ite.arr" :key="indexa" >
                  {{ites.title}}
                </div>
              </div>
         </div>
          <!-- 解决方案 -->
          <div v-if="indexcure === 2" @mouseleave="changeColormouseleave" class="animate__animated animate__fadeIn absolute top-[57px] z-[1] right-[150px] bg-[#fff] botderbottom1 flex text-left">
              <div class="flex-1 px-[25px] botderright my-[30px] text-center" v-for="(ite,indexs) in menu3" :key="indexs">
                <div class="mb-[16px]"><img class="w-[15px] h-[18px] inline-block mr-[5px]" src="../assets/logo/icon_1.jpg" alt="">{{ite.title}}</div>
                <div class="font-[400] text-[15px] mb-[10px] text-[#777] hover:text-[#1936AD]" v-for="(ites,indexa) in ite.arr" :key="indexa" @click="navigateToref('/solutionDetails',{search:ites.title1})">
                  {{ites.title}}
                </div>
              </div>
         </div>
         <!-- 技术支持 -->
         <div v-if="indexcure === 3" @mouseleave="changeColormouseleave" class="animate__animated animate__fadeIn absolute top-[57px] z-[1] right-[80px] bg-[#fff] botderbottom1 flex text-left">
              <div class="flex-1 px-[25px] botderright my-[30px] text-center" v-for="(ite,indexs) in menu4" :key="indexs">
                <!-- <div class="mb-[16px]"><img class="w-[15px] h-[18px] inline-block mr-[5px]" src="../assets/logo/icon_1.jpg" alt="">{{ite.title}}</div> -->
                <div class="font-[400] text-[15px] mb-[10px] text-[#777] hover:text-[#1936AD]" v-for="(ites,indexa) in ite.arr" @click="clicktws(ites)" :key="indexa" >
                  {{ites.title}}
                </div>
              </div>
         </div>
          <!-- 关于我们 -->
          <div v-if="indexcure === 4" @mouseleave="changeColormouseleave" class="animate__animated animate__fadeIn absolute top-[57px] z-[1] right-[-20px] bg-[#fff] botderbottom1 flex text-left">
              <div class="flex-1 px-[25px] botderright my-[30px] text-center" v-for="(ite,indexs) in menu5" :key="indexs">
                <!-- <div class="mb-[16px]"><img class="w-[15px] h-[18px] inline-block mr-[5px]" src="../assets/logo/icon_1.jpg" alt="">{{ite.title}}</div> -->
                <div class="font-[400] text-[15px] mb-[10px] text-[#777] hover:text-[#1936AD]" v-for="(ites,indexa) in ite.arr" :key="indexa" @click="navigateTo(ites.path)">
                  {{ites.title}}
                </div>
              </div>
         </div>
      </div>
      <!-- 移动端 -->
      <div class="ml-auto  justify-center items-center flex max-991min">
       <div @click="show_moble = true">
        <el-icon size="26px" color="#fff"><Expand  /></el-icon>
       </div>
        <div class="bg-[#fff] w-full fixed top-0 left-0 py-[50px] z-[999]" v-if="show_moble">
                <div class="absolute top-[10px] right-[10px] z-[99]"><el-icon color="#000" size="26px" @click="show_moble = false"><Close /></el-icon></div>
                <el-tree :data="movemoble" :props="{ class: 'customNodeClass' }"  auto-expand-parent	 @node-click="handleNodeClick" />
            </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script setup>
import {ref,defineProps} from 'vue'
import { navigateTo,navigateToref } from '../utils/jump';
import 'animate.css';
const props = defineProps({
  acticechun:{
    type:Number,
    default:1
  }
})
const show_moble = ref(false)
 const datares = ref([
  // {title:"OPEN IITA",index:0,path:'https://www.openiita.com/'},
  {title:"首页",index:0,path:'/'},
  {title:"产品中心",index:1,path:'/productCenter'},
  {title:"解决方案",index:2,path:'/solution'},
  {title:"技术支持",index:3},
  {title:"关于我们",index:4},
  // {title:"OPEN IITA",index:5,path:'https://www.openiita.com/'},
 ])
 const menu2 = ref([
  {title:"传感器",arr:[
    {title:"压力传感器"},
    {title:"超声波传感器"},
  ]},
  {title:"永磁电机",arr:[
    {title:"通用水润滑电机"},
    {title:"通用轴承电机"},
  ]},
  {title:"电控",arr:[
    {title:"电控"},
  ]},
  {title:"流道设计",arr:[
    {title:"流道设计"},
  ]},
 ])
 const menu3 = ref([
  {title:"软件解决方案",arr:[
    {title:"智能水泵",title1:'水泵'},
    {title:"智能充电桩",title1:'充电桩'},
    {title:"智慧水务",title1:'水务'},
    // {title:"智慧农业",title1:'大鹏'},
    {title:"智慧建筑",title1:'建筑能耗'},
    {title:"OPENIITA开放平台",title1:'OPENIITA开放平台'},
  ]},
 ])
 const menu4 = ref([
  {title:"技术支持",arr:[
    {title:"技术支持",path:'/technicalSupport'},
    {title:"开源平台",path:'/technicalSupport'},
  ]},
 ])
 const menu5 = ref([
  {title:"关于铱塔",arr:[
    {title:"关于铱塔",path:'/aboutTower'},
    {title:"合作伙伴",path:'/partners'},
    {title:"购买渠道",path:'/purchasingchannels'},
  ]},
 ])

 const movemoble = ref([
{
    label: '首页',
    path:'/'
},
{
    label: '产品中心',
    lab:true,
    children: [
      {
        label: '传感器',
        path:'/productCenter',
        title:'传感器',
        lab:true,
        children:[
          {
            label: '压力传感器',
            path:'/productCenter',
            title:'传感器',
          }
        ]
      },
      {
        label: '永磁电机',
        path:'/productCenter',
        lab:true,
        children:[
          {
            label: '通用水润滑电机',
            path:'/productCenter',
            title:'永磁电机',
          },
          {
            label: '通用轴承电机',
            path:'productList',
            title:'永磁电机',
          }
        ]
      },
      {
        label: '电控',
        lab:true,
        path:'productList',
        children:[
          {
            label: '电控',
            path:'productCenter',
            title:'电控'
          },
        ]
      },
      {
        label: '流道设计',
        lab:true,
        children:[
          {
            label: '流道设计',
            path:'/productCenter',
            title:'流道设计'
          },
        ]
      },
    ],
  },
  {
    label: '软件解决方案',
    lab:true,
    children: [
      {
        label: '智能水泵',
        path:'/solutionDetails',
        title:'水泵'
      },
      {
        label: '智能充电桩',
        path:'/solutionDetails',
        title:'充电桩'
      },
      {
        label: '智慧水务',
        path:'/solutionDetails',
        title:'水务'
      },
      {
        label: '智慧充电桩',
        path:'/solutionDetails',
        title:'充电桩'
      },
      // {
      //   label: '智慧农业',
      //   path:'/solutionDetails',
      //   title:'大鹏'
      // },
      {
        label: '智慧建筑',
        path:'/solutionDetails',
        title:'建筑能耗'
      },
      {
        label: 'OPENIITA开放平台',
        path:'/solutionDetails',
        title:'OPENIITA开放平台'
      },
    ],
  },
  {
        label: '技术支持',
        url:'news',
        lab:true,
        children:[
          {
            label: '技术支持',
            path:'/technicalSupport',
          },
          {
            label: '开源平台',
            path:'/technicalSupport',
          },
        ]
  },
  {
        label: '关于我们',
        url:'aboutUs',
        lab:true,
        children:[
          {
            label: '关于铱塔',
            path:'/aboutTower',
          },
          {
            label: '合作伙伴',
            path:'/partners',
          },
          {
            label: '购买渠道',
            path:'/purchasingchannels',
          },
        ]
  },
])
  const indexcure = ref(0)
 const changeColor = (index)=>{
   indexcure.value = index
 }
 const changeColormouseleave = ()=>{
   indexcure.value = ''
 }

 const clicktws = (ites)=>{
  console.log(ites)
  if(ites.title == '开源平台'){
    window.open('https://www.openiita.com', '_blank');
    return
  }
  
  navigateToref(ites.path)
 }

 const handleNodeClick = (e)=>{
  // console.log(e,{title:e.search})
   if(!e.lab ){
    navigateToref(e.path,{title:e.title,search:e.title})
   }
 }

</script>
<style scope>
.actice{
  color: #1936AD;
}

.botderbottom{
  border-bottom: 1px solid #fff;
}
.botderbottom1{
  border-top: 2px solid #e2e2e2;
}
.botderright{
  border-right: 1px solid #e8e8e8;
}
.max-991min{
    display: none !important;
}
@media (max-width: 991px) {
    .min-991max{
        display: none !important;
    }
    .max-991min{
        display: block !important;
    }
}
.activesce{
  background: #fff;
  z-index: 101;
  
}
.activesce .textfff{
  color: #000 !important;
}
.bor_btom{
  border-bottom:1px solid rgba(255, 255, 255, .1);
}
</style>
